import {Injectable, Optional, inject} from '@angular/core';
import {NotificationData} from './firebase-data-interface';
import {CommunicationService, GatewayApi} from '@hrs/providers';
import {ILocalNotification, LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {RemoteUpdaterService} from '../../hrs-tablet/apk/remote-updater.service';
import {BuildUtility} from '@hrs/utility';
import {RestartDeviceIntentService} from '../../hrs-tablet/knox-service-intents/restart-device-intent.service';
import {TabletDeviceIdService} from '../tablet-device-id/tablet-device-id';
import {RemoteDiagnosticsService} from '../../hrs-tablet/remote-view/remote-diagnostics.service';
import {KioskIntentService} from '../../hrs-tablet/knox-service-intents/kiosk-intent.service';
import {EventService} from '../events/event.service';
import {Notification, Importance, Visibility} from '@capacitor-firebase/messaging';
import {Subscription, Observable, lastValueFrom} from 'rxjs';
import {User} from '@patient/providers';
import {getLogger} from '@hrs/logging';
import {FirebaseMessagingService} from './firebase-messaging.service';
import {FIREBASE_MESSAGING_PLUGIN} from '@app/native-plugins';

/**
 * This is the Firebase class that will be used for firebase messaging and notifications
 */
@Injectable({
    providedIn: 'root',
})
export class FirebaseNotifications {
    private readonly logger = getLogger('FirebaseNotifications');

    shouldUpdateToken: boolean;
    static notification: any;
    static STATUS_CHANGE: string = 'status_change';
    static STATUS_DEACTIVATE: string = 'deactivate';
    static UPDATE_APK: string = 'updateapk';
    static RESTART_DEVICE: string = 'restart';
    static UPDATE_MODULE: string = 'updatemodule';
    static REMOVE_PATIENT: string = 'remove_patient';
    static ADD_PATIENT: string = 'add_patient';
    static KIOSK_LOCK: string = 'lock';
    static KIOSK_UNLOCK: string = 'unlock';
    private static REMOTE_VIEW = 'remoteview';
    static METRIC = 'metric';
    static DEV_INFO = 'devinfo';
    static LOG_DUMP = 'logdump';
    token: string;
    private onTokenRefreshSubscription: Subscription;
    private onNotificationSubscription: Subscription;
    private user: User;
    private readonly fcm = inject(FIREBASE_MESSAGING_PLUGIN);

    constructor(
        private communication: CommunicationService,
        private eventService: EventService,
        private gatewayApi: GatewayApi,
        private localNotifications: LocalNotifications,
        private restartDeviceIntent: RestartDeviceIntentService,
        private firebaseMessagingService: FirebaseMessagingService,
        @Optional() private tabletDeviceIdService: TabletDeviceIdService,
        @Optional() private remoteUpdater: RemoteUpdaterService,
        @Optional() private remoteDiagnostics: RemoteDiagnosticsService,
        @Optional() private kiosk: KioskIntentService
    ) {}

    /**
     * Manually Ask for Push Permission (IOS ONLY)
     * Once user answers yes/no, they will not be asked again
     */
    public askIOSPushPermission(): Promise<void> {
        this.logger.debug(`askIOSPushPermission()`);
        return this.fcm.requestPermissions().then((permissionGranted) => {
            if (permissionGranted) {
                this.logger.phic.debug('Push Permission Granted');
            } else {
                this.logger.phic.debug('Push Permission Declined');
            }
        }, (err) => {
            this.logger.phic.error('Failed to request push permission', err);
        });
    }

    /**
     * Create Channel on Device for Firebase to send Notifications to
     */
    public createAndroidNotificationChannel(): Promise<void> {
        this.logger.debug(`createAndroidNotificationChannel()`);
        return this.fcm.createChannel({
            id: 'default-channel-id',
            name: 'Default channel',
            importance: Importance.Max,
            visibility: Visibility.Public
        }).then((res) => {
            this.logger.phic.debug('Successfully created Notification Channel');
        }, (err) => {
            this.logger.phic.error('Failed to create Notification Channel', err);
        });
    }

    /**
     * Subscribe to Firebase topics
     */
    public initializeFirebase(user: User) {
        this.logger.debug(`initializeFirebase()`);
        this.user = user;
        this.shouldUpdateToken = true;
        // Subscribe to topic and get device's current registration id
        this.fcm.subscribeToTopic({topic: 'all'}).then( (res) => {
            this.logger.phic.debug('Successfully subscribed to firebase topics');
        }, (err) => {
            this.logger.phic.error('Failed to subscribe to firebase topics', err);
        });
        // get firebase device token
        this.fcm.getToken().then(({token}) => {
            this.logger.phic.debug('Firebase token: ' + token);
            if (BuildUtility.isHRSTab()) this.eventService.updateDeviceProfile.next({token: token});
            // store video call firebase token
            this.updateToken(token);
            this.buildNotificationHandler();
        }, (err) => {
            this.logger.phic.error('Failed to get firebase token', err);
            // this is a common error returned from firebase which usually indicates the device has no internet connection
            if (err.message.includes('SERVICE_NOT_AVAILABLE')) {
                const connectionListener = () => {
                    this.initializeFirebase(this.user);
                    window.removeEventListener('online', connectionListener);
                };
                window.addEventListener('online', connectionListener);
            }
        });
    }

    /**
     * Get last notification that on tap, opened the app.
     * Payload will be null if the app was opened normally.
     */
    public async getInitialPushPayload(): Promise<any> {
        this.logger.trace(`getInitialPushPayload()`);
        const result = await this.fcm.getDeliveredNotifications();
        return result?.notifications;
    }

    private async updateToken(token: string): Promise<void> {
        this.logger.debug(`updateToken() token = ${token}`);
        if (token){
            try {
                if (BuildUtility.isHRSTab()) await this.sendDeviceTokenToServer(token);
                if (this.user.isPatient) await this.sendPatientTokenToServer(token);
            } catch (e) {
                this.logger.phic.error('Failed to submit firebase token', e);
            }
        } else {
            this.logger.debug(`Firebase token is not available`);
        }
    }

    /**
     * Listen for Firebase notifications and broadcast the appropriate event
     */
    buildNotificationHandler(): void {
        this.logger.debug(`buildNotificationHandler()`);
        if (this.onNotificationSubscription) return;
        this.onNotificationSubscription = this.firebaseMessagingService.notification$.subscribe((notification: Notification) => {
            let data = notification as any;
            this.logger.debug(`fcm.onNotification(): ${JSON.stringify(data, null, '\t')}`);
            if (data?.jsonData) data = {...data, ...JSON.parse(data.jsonData)};
            if (data?.data?.jsonData) data = {...data, ...JSON.parse(data.data.jsonData)};
            if (data?.data && typeof data.data === 'string') data.data = JSON.parse(data.data);
            this.logger.debug(`notification after mutations: ${JSON.stringify(data, null, '\t')}`);
            this.acknowledgeNotification(data.id, 'complete');
            if (data.trigger) { // metric notifications
                // some metric notifications have 'data' that is stringified twice
                while (typeof data.data === 'string') {
                    try {
                        data.data = JSON.parse(data.data);
                    } catch (e) {
                        this.logger.phic.error('Error parsing notification data', e);
                    }
                }
                // manually fire LocalNotification service events for subscriptions in Chat component
                this.localNotifications.fireEvent('trigger', data);
                if (data.wasTapped) {
                    this.localNotifications.fireEvent('click', data);
                }
            } else if (data.type && data.type.includes('video')) {
                if (data.action === 'incoming_call') {
                    this.communication.notifyIncomingVideoCall(data);
                } else if (data.action === 'call_unanswered' || data.action === 'call_declined') {
                    this.communication.notifyRemoteVideoCalleeDidNotRespond(data);
                } else if (data.action === 'call_left') {
                    this.communication.notifyRemoteVideoCalleeLeft(data);
                }
                // data.type === 'text' for patient/clinician chat. data.type === 'chat' for patient/caregiver.
            } else if (data.type === 'text' || data.type === 'chat') {
                this.communication.notifyNewChatMessage(data);
            } else if (data.type === 'voicecall') {
                if (data.action === 'call_unanswered' || data.action === 'call_declined') {
                    this.communication.notifyRemoteVoiceCalleeDidNotRespond(data);
                } else if (data.action === 'call_left') {
                    this.communication.notifyRemoteVoiceCalleeLeft(data.data);
                } else {
                    this.communication.notifyIncomingVoiceCall(data);
                }
            } else if (data.action === FirebaseNotifications.STATUS_CHANGE) {
                if (BuildUtility.isHRSTab() && data.status === FirebaseNotifications.STATUS_DEACTIVATE) {
                    this.eventService.incomingNotification.next(FirebaseNotifications.STATUS_DEACTIVATE);
                } else {
                    this.eventService.incomingNotification.next(FirebaseNotifications.STATUS_CHANGE);
                }
            } else if (data.type === FirebaseNotifications.UPDATE_APK) {
                this.remoteUpdater.listen.next(data.data);
            } else if (data.type === FirebaseNotifications.UPDATE_MODULE) {
                if (BuildUtility.isHRSTab()) {
                    this.eventService.postCompleteDeviceProfileEvent.next();
                    if (data.action === FirebaseNotifications.ADD_PATIENT) {
                        this.eventService.addPatient.next();
                    } else if (data.action === FirebaseNotifications.REMOVE_PATIENT) {
                        this.eventService.removePatient.next();
                    } else {
                        this.handleUpdateModule(data);
                    }
                } else {
                    this.handleUpdateModule(data);
                }
            } else if (BuildUtility.isHRSTab() && data.type === FirebaseNotifications.RESTART_DEVICE) {
                this.eventService.restartDevice.next();
                this.restartDeviceIntent.restartDevice();
            } else if (BuildUtility.isHRSTab() && data.type === FirebaseNotifications.REMOTE_VIEW) {
                this.remoteDiagnostics.handleNotification(data.data);
            } else if (BuildUtility.isHRSTab() && data.type === FirebaseNotifications.KIOSK_LOCK) {
                this.kiosk.enableKioskMode();
            } else if (BuildUtility.isHRSTab() && data.type === FirebaseNotifications.KIOSK_UNLOCK) {
                this.kiosk.disableKioskMode();
            } else if (data.type === FirebaseNotifications.METRIC) {
                this.eventService.incomingNotification.next(FirebaseNotifications.METRIC);
            } else if (data.type === FirebaseNotifications.DEV_INFO) {
                if (BuildUtility.isHRSTab()) this.eventService.postCompleteDeviceProfileEvent.next();
            } else if (data.type === FirebaseNotifications.LOG_DUMP) {
                this.eventService.remoteLogDumpTriggered.next();
            }
        }, (error) => {
            this.logger.phic.error('Failed to initialize firebase notification listener', error);
        });
        this.eventService.handlePendingNotificationTap.next(true);

        this.tokenRefresh();
    }

    public tokenRefresh(): void {
        this.logger.trace(`tokenRefresh()`);
        if (this.onTokenRefreshSubscription) {
            this.logger.trace(`tokenRefresh() subscription already active`);
            return;
        }
        this.onTokenRefreshSubscription = this.firebaseMessagingService.tokenRefresh$.subscribe((token: string) => {
            const shouldUpdate = this.shouldUpdateToken;
            this.logger.trace(`fcm.onTokenRefresh() -> token = ${token}, shouldUpdate = ${shouldUpdate}`);
            if (shouldUpdate) {
                this.updateToken(token);
            }
        });
    }

    /**
     * Store Firebase device token
     * @param token
     */
    public async sendPatientTokenToServer(token): Promise<any> {
        this.logger.debug(`sendPatientTokenToServer()`);
        try {
            const data = {
                data: {
                    value: token,
                    hrsid: this.user.id,
                    deviceType: BuildUtility.isHRSTab() ? 'tablet' : 'mobile'
                },
            };
            return await lastValueFrom(this.postToken(data));
        } catch (e) {
            this.logger.phic.error('Error posting patient firebase token ', e);
        }
    }

    public async sendDeviceTokenToServer(token): Promise<any> {
        this.logger.debug(`sendDeviceTokenToServer()`);
        let deviceId;
        try {
            deviceId = await this.tabletDeviceIdService.getImei();
            const data = {
                data: {
                    value: token,
                    deviceType: 'tablet',
                    deviceId: deviceId
                }
            };
            return await lastValueFrom(this.postToken(data));
        } catch (e) {
            this.logger.phic.error('Error posting device firebase token ', e);
        }
    }

    private postToken(data: {data: { value: string, deviceType: string, deviceId?: string, hrsid?: string}}): Observable<any> {
        this.logger.debug(`postToken() data = ${JSON.stringify(data)}`);
        return this.gatewayApi.post(
            'push-tokens',
            data
        );
    }

    public async deleteInstanceId(): Promise<void> {
        this.logger.debug(`deleteInstanceId()`);
        this.user = null;
        // in PCMT we only submit a device token and we always want to be receiving notifications whether or not
        // a patient is assigned, no need to delete the instance id on log out
        if (BuildUtility.isHRSTab()) return;
        this.shouldUpdateToken = false;

        // prevents signed out PCM users from receving notifications
        try {
            await this.fcm.deleteChannel({id: 'default-channel-id'});
            await this.fcm.deleteToken();
            this.logger.phic.debug('Firebase instance successfully deleted');
        } catch (e) {
            this.logger.phic.error('Failed to delete firebase instance', e);
        }
    }

    public async clearFcmNotifications(notifications: Notification[]): Promise<void> {
        this.logger.debug(`clearNotifications()`);
        try {
            await this.fcm.removeDeliveredNotifications({notifications: notifications});
        } catch (e) {
            this.logger.phic.error('Failed to clear FCM notifications', e);
        }
    }

    private handleUpdateModule(notificationData: ILocalNotification | NotificationData): void {
        this.logger.debug(`handleUpdateModule()`, notificationData);
        let data = notificationData.data;
        this.logger.debug(`parsed data = `, data);
        if (data && data.reset && data.reset.length === 0) {
            this.eventService.incomingNotification.next(FirebaseNotifications.UPDATE_MODULE);
        } else {
            this.eventService.incomingNotification.next(data);
        }
    }

    private acknowledgeNotification(notificationId: string, status: 'ack' | 'complete'): void {
        this.logger.debug(`acknowledgeNotification()`, {notificationId, status});
        const path = `push-notifications/${notificationId}`;
        this.gatewayApi.patch(path, {data: {status: status}}).subscribe(() => {
            this.logger.phic.debug(`Successfully acknowledged notification id: ${notificationId}`);
        }, (err) => {
            this.logger.phic.error(`Failed to acknowledge notification id: ${notificationId}`, err);
        });
    }
}
