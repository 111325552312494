import {Injectable, inject} from '@angular/core';
import {getLogger} from '@hrs/logging';
import {Subject, Observable, Subscription} from 'rxjs';
import {share} from 'rxjs/operators';
import {isString} from 'lodash';
import {FIREBASE_MESSAGING_PLUGIN} from '@app/native-plugins';
import {
    Notification,
    TokenReceivedEvent,
    NotificationReceivedEvent,
    NotificationActionPerformedEvent,
    TokenReceivedListener,
    NotificationReceivedListener,
    NotificationActionPerformedListener
} from '@capacitor-firebase/messaging';
import {EventService} from '../events/event.service';
import {User} from '../user/user.service';
import {Platform} from '@ionic/angular';

const MAX_DELEGATE_SET_ATTEMPTS = 3;

export interface PcmNotification extends Notification {
    wasTapped?: boolean;
    actionMetadata?: NotificationActionPerformedEvent;
}

@Injectable({
    providedIn: 'root'
})
export class FirebaseMessagingService {
    private readonly logger = getLogger('FirebaseMessagingService');

    private readonly tokenRefreshSubject = new Subject<string>();
    private readonly notificationSubject = new Subject<PcmNotification>();
    private readonly tokenListener: TokenReceivedListener;
    private readonly notificationListener: NotificationReceivedListener;
    private readonly notificationActionPerformedListener: NotificationActionPerformedListener;
    private readonly messaging = inject(FIREBASE_MESSAGING_PLUGIN);

    private setEventDelegateAttempts: number = 0;

    public readonly tokenRefresh$: Observable<string>;
    public readonly notification$: Observable<Notification>;
    private handlePendingNotificationTapSubscription: Subscription;
    private recentNotifications: Map<string, number> = new Map(); // Stores notification ID and timestamp
    private discardThreshold: number = 60 * 1000; // 1 minute in milliseconds

    constructor(
        private readonly eventService: EventService,
        private platform: Platform
    ) {
        this.tokenListener = this.onTokenReceived.bind(this);
        this.notificationListener = this.onNotificationReceived.bind(this);
        this.notificationActionPerformedListener = this.onNotificationActionPerformed.bind(this);
        this.tokenRefresh$ = this.tokenRefreshSubject.asObservable().pipe(share());
        this.notification$ = this.notificationSubject.asObservable().pipe(share());
    }

    public async initialize(): Promise<void> {
        this.logger.trace(`initialize()`);
        await this.trySetEventDelegate();
    }

    private async trySetEventDelegate(): Promise<void> {
        this.logger.trace(`trySetEventDelegate()`);
        if (this.setEventDelegateAttempts >= MAX_DELEGATE_SET_ATTEMPTS) {
            this.logger.warn(`trySetEventDelegate() max attempts exceeded! -> ${MAX_DELEGATE_SET_ATTEMPTS}`);
            return;
        }

        this.setEventDelegateAttempts++;

        try {
            await this.messaging.addListener('tokenReceived', this.tokenListener);
            await this.messaging.addListener('notificationReceived', this.notificationListener);
            await this.messaging.addListener('notificationActionPerformed', this.notificationActionPerformedListener);
        } catch (e) {
            this.onSharedEventDelegateError(e);
        }
    }

    private onTokenReceived(ev: TokenReceivedEvent): void {
        this.logger.debug('---onTokenReceived---');
        if (isString(ev?.token)) {
            this.tokenRefreshSubject.next(ev.token);
        } else {
            this.logger.warn(`onTokenReceived event missing token value`, ev);
        }
    }

    private onNotificationReceived(ev: NotificationReceivedEvent): void {
        if (ev?.notification) {
            let receivedNotification = ev.notification;
            this.logger.debug('onNotificationReceived ' + receivedNotification?.id);
            // iOS 18 is presenting the notification twice thus handled it https://forums.developer.apple.com/forums/thread/761597
            if (this.platform.is('ios') && this.recentNotifications.has(receivedNotification?.id)) {
                const now = Date.now();
                const lastTimestamp = this.recentNotifications.get(receivedNotification?.id)!;
                if (now - lastTimestamp < this.discardThreshold) {
                    this.logger.debug('Discarding duplicate notification with id ' + receivedNotification?.id);
                    return;
                }
            }
            this.notificationSubject.next(receivedNotification);
            if (this.platform.is('ios')){
                // Update the recent notifications map with the current timestamp
                this.recentNotifications.set(receivedNotification?.id, Date.now());
                this.cleanUpOldEntries();
            }
            this.logger.debug('Got the notification with id' + receivedNotification?.id);
        } else {
            this.logger.warn(`onNotificationReceived event missing notification value`, ev);
        }
    }

    // Loops through the recentNotifications map to delete any entries older than the discardThreshold.
    private cleanUpOldEntries() {
        const now = Date.now();
        for (const [id, timestamp] of this.recentNotifications.entries()) {
            if (now - timestamp > this.discardThreshold) {
                this.recentNotifications.delete(id); // Remove entries older than the threshold
            }
        }
    }

    private onNotificationActionPerformed(ev: NotificationActionPerformedEvent): void {
        if (ev?.notification) {
            const wasTapped = ev.actionId === 'tap';
            const actionMetadata = ev;
            const notification: PcmNotification = {actionMetadata, wasTapped, ...ev.notification};
            this.logger.info(`onNotificationActionPerformed notification`, notification);
            if (User.getToken()) {
                // When the app is in the background and the user taps on a notification banner,
                // bypass the login process and directly pass the notification object to the notificationSubject
                this.notificationSubject.next(notification);
            } else {
                this.logger.info(`onNotificationActionPerformed notification getToken not set`, notification);
                // When the app is closed and the user taps on a notification banner,
                // wait for buildNotificationHandler to be configured after login using handlePendingNotificationTap subject,
                // then pass the notification object to the notificationSubject
                this.handlePendingNotificationTapSubscription = this.eventService.handlePendingNotificationTap.subscribe({
                    next: (fcmInitialized: boolean) => {
                        this.logger.info(`eventService.handlePendingNotificationTap fcmInitialized = ${fcmInitialized}`);
                        if (fcmInitialized) {
                            this.logger.info(`notificationSetupDone notification`, notification);
                            this.notificationSubject.next(notification);
                        }
                        if (this.handlePendingNotificationTapSubscription) {
                            this.handlePendingNotificationTapSubscription.unsubscribe();
                            this.handlePendingNotificationTapSubscription = undefined;
                        }
                    },
                    error: (err) => {
                        this.logger.error(`eventService.handlePendingNotificationTap failed`, err);
                    }
                });
            }
        } else {
            this.logger.warn(`onNotificationActionPerformed event missing notification value`, ev);
        }
    }

    private onSharedEventDelegateError(error: any): void {
        this.logger.warn(`onSharedEventDelegateError()`, error);
        this.logger.warn(`will attempt to re-initialize the event delegate`);
        setTimeout(() => this.trySetEventDelegate(), 1000);
    }
}
